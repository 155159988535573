<template>
  <!--      当上级查看下级展示-->
  <div
    v-if="isSuperior"
    class="flex margin-top justify-center"
    style="color: rgba(0, 0, 0, 0.85)"
  >
    <div class="flex align-center" style="width: 960px">
      <p class="PerformanceDetails-name margin-right-lg">
        {{ planList.publishName }}
      </p>
      <p class="PerformanceDetails-position">
        {{ planList.departmentName }} —— {{ planList.position }}
      </p>
    </div>
  </div>
  <div
    class="PerformanceDetails flex justify-center"
    :style="{
      height: isSuperior ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
    }"
  >
    <div
      class="box-ing content-box margin-top bg-white border-round-12"
      style="position: relative"
    >
      <div class="flex padding-lr" style="flex-direction: row-reverse">
        <el-tooltip
          v-if="noSuperiorOperation === 0"
          class="item"
          effect="dark"
          content="撤回"
          placement="bottom"
        >
          <div @click="onNoSuperiorOperation" class="cursor-pt text-center">
            <i class="icon noSuperiorOperation" />
            <span style="font-size: 14px">撤回</span>
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="resubmit === 1"
          class="item"
          effect="dark"
          content="再次提交"
          placement="bottom"
        >
          <div @click="onResubmit" class="cursor-pt text-center">
            <i class="icon resubmit" style="margin: 0 auto" />
            <span style="font-size: 14px">再次提交</span>
          </div>
        </el-tooltip>
      </div>
      <i
        v-if="planState === 5 && !isSuperior"
        class="icon status status-self-evaluated"
      />
      <el-form
        :model="planList"
        label-width="100px"
        ref="planRef"
        class="PerformanceDetails-form"
      >
        <div
          class="bg-white border-round-12 padding-tb padding-lr-xl"
          :style="{
            height:
              planList.jointAssessEntities.length === 0 || planState === 7
                ? 'auto'
                : '100%',
          }"
        >
          <div
            v-for="(item, index) in planList.info"
            :key="index"
            class="PerformanceDetails-form-box padding-bottom margin-bottom-lg"
          >
            <div class="flex justify-between margin-bottom">
              <div>目标({{ index + 1 }})</div>
            </div>
            <el-form-item
              :prop="'info.' + index + '.corePerformanceIndicators'"
              label="核心工作指标 :"
            >
              <div>{{ item.corePerformanceIndicators }}</div>
            </el-form-item>
            <el-form-item
              :prop="'info.' + index + '.indicatorsStandardValue'"
              label="指标标准值 :"
            >
              <div>{{ item.indicatorsStandardValue }}</div>
            </el-form-item>
            <el-form-item
              :prop="'info.' + index + '.standardEvaluation'"
              label="评分标准 :"
            >
              <div>{{ item.standardEvaluation }}</div>
            </el-form-item>
            <div class="flex justify-between">
              <el-form-item
                :prop="'info.' + index + '.planTime'"
                label="计划用时(h) :"
              >
                <div>{{ item.planTime }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.weight'"
                label="权重(配分100) :"
              >
                <div>{{ item.weight }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.dataFromDepartment'"
                label="数据来源部门 :"
              >
                <div>{{ item.dataFromDepartment }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.evaluationScores'"
                :rules="[
                  {
                    required: planState === 5,
                    message: '得分必填',
                    trigger: 'blur',
                  },
                ]"
                label="得分 :"
                v-if="
                  (Number(planState) > 5 || !isSuperior) &&
                  Number(planState) > 2
                "
              >
                <el-input
                  placeholder="请输入得分"
                  type="number"
                  v-if="planState === 5"
                  v-model="item.evaluationScores"
                  @change="
                    (e) => {
                      zongFen(e, index);
                    }
                  "
                />
                <div v-else style="color: #fb4343">
                  {{ item.evaluationScores }}
                </div>
              </el-form-item>
            </div>
            <div
              class="wcxq"
              v-if="
                (Number(planState) >= 5 && planUser === userid) ||
                Number(planState) > 5
              "
            >
              <el-form-item
                :prop="'info.' + index + '.completion'"
                :rules="[
                  {
                    required: planState === 5,
                    message: '完成情况',
                    trigger: 'blur',
                  },
                ]"
                label="完成情况 :"
              >
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入完成情况"
                  v-if="planState === 5 && planUser === userid"
                  v-model="item.completion"
                />
                <div v-else>{{ item.completion }}</div>
              </el-form-item>
              <div style="margin-bottom: 30px">
                <div style="margin: 20px 0">
                  <span>图片:</span>
                  <span class="zdxz">(最多选择9张)</span>
                </div>
                <el-upload
                  class="upload-demo"
                  action
                  :before-upload="
                    (e) => {
                      upload(e, index);
                    }
                  "
                  :on-preview="handlePreview"
                  :on-exceed="exceedImg"
                  :file-list="item.pictures || []"
                  :disabled="planUser !== userid || Number(planState) !== 5"
                  limit="9"
                  list-type="picture-card"
                >
                  <template #default>
                    <i class="el-icon-plus"></i>
                  </template>
                  <template #file="{ file }">
                    <div>
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleDownload(file)"
                        >
                          <i class="el-icon-download"></i>
                        </span>
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                  <img
                    style="width: 100%; height: 50vh"
                    :src="dialogImageUrl"
                    alt=""
                  />
                </el-dialog>
              </div>
              <div>
                <el-upload
                  class="upload-demo"
                  action
                  :before-upload="
                    (e) => {
                      upload2(e, index);
                    }
                  "
                  :on-preview="handlePreview2"
                  :on-remove="handleRemove2"
                  :on-exceed="onExceed"
                  :file-list="item.attachment || []"
                  :disabled="planUser !== userid || Number(planState) !== 5"
                  limit="9"
                >
                  <span>附件:</span>
                  <el-button class="sctup">添加附件</el-button>
                  <template #tip1></template>
                </el-upload>
                <div>
                  <el-button
                    class="sctup"
                    @click="
                      (e) => {
                        download(index);
                      }
                    "
                    >下载</el-button
                  >
                  <el-button class="sctup" @click="showFile">预览</el-button>
                </div>
              </div>
            </div>
          </div>
          <template
            v-if="
              (Number(planState) > 5 || !isSuperior) && Number(planState) > 2
            "
          >
            <div class="flex justify-between">
              <p>自我评价</p>
              <div class="fraction">{{ planList.selfAssessScore }}</div>
            </div>
            <el-form-item
              prop="selfAssessAdvantage"
              label="优势 :"
              :rules="[
                {
                  required: planState === 5,
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-if="planState === 5"
                type="textarea"
                placeholder="请输入优势"
                v-model="planList.selfAssessAdvantage"
                maxlength="200"
                show-word-limit
              />
              <div v-else>{{ planList.selfAssessAdvantage }}</div>
            </el-form-item>
            <el-form-item
              prop="selfAssessImprovement"
              label="劣势 :"
              :rules="[
                {
                  required: planState === 5,
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-if="planState === 5"
                type="textarea"
                placeholder="请输入劣势"
                v-model="planList.selfAssessImprovement"
                maxlength="200"
                show-word-limit
              />
              <div v-else>{{ planList.selfAssessImprovement }}</div>
            </el-form-item>
          </template>
        </div>
        <!--      会审列表-->
        <div
          v-if="
            (planList.jointAssessEntities.length !== 0 ||
              isJoinAssessor === 1) &&
            Number(planState) > 5
          "
          class="
            bg-white
            border-round-12
            padding-tb
            box-ing
            padding-lr-xl
            margin-top
          "
        >
          <div class="margin-bottom flex justify-between align-center">
            <p>会审复评</p>
          </div>
          <template v-if="planList.jointAssessEntities.length === 0">
            <el-form-item
              prop="jointReviewScore"
              :rules="[
                { required: true, message: '得分必填', trigger: 'blur' },
              ]"
              label="复核得分"
            >
              <el-input-number
                v-model="planList.jointReviewScore"
                placeholder="请输入复核得分"
                :min="0"
                :precision="2"
              />
            </el-form-item>
            <el-form-item
              prop="jointReviewAdvantage"
              label="会审意见 :"
              :rules="[
                { required: true, message: '会审意见必填', trigger: 'blur' },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="请会审意见"
                v-model="planList.jointReviewAdvantage"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </template>
          <div
            v-else
            v-for="(item, index) in planList.jointAssessEntities"
            :key="index"
            class="jointHearing padding-top-lg"
          >
            <div class="flex justify-between">
              <div class="flex align-center">
                <div class="jointHearing-img flex align-center justify-center">
                  <img
                    v-if="item.avatar && item.avatar?.length !== 0"
                    :src="item?.avatar"
                  />
                  <span v-else> {{ item.jointPerson.slice(-2) }}</span>
                </div>
                <p class="jointHearing-name margin-left">
                  {{ item.jointPerson }}
                </p>
              </div>
              <div class="fraction">{{ item.jointReviewScore }}</div>
            </div>

            <el-form-item label="会审意见 :">
              <div>{{ item.jointReviewAdvantage }}</div>
            </el-form-item>
          </div>
        </div>
        <!--上级-->
        <div class="padding-lr-xl margin-bottom-30">
          <div
            class="flex jointHearing padding-top justify-between align-center"
            v-if="planList.reviewEntityList.length > 0"
          >
            <p>复评</p>
            <div class="flex align-center justify-center" v-if="sum > 0">
              <span style="font-size: 16px; color: rgba(0, 0, 0, 0.45)"
                >会审得分均值:</span
              ><span class="fraction">{{ sum }}</span>
            </div>
          </div>
          <div
            v-for="(item, index) in planList.reviewEntityList"
            :key="index"
            class="padding-top-lg"
          >
            <div class="flex justify-between">
              <div class="flex align-center">
                <div class="jointHearing-img flex align-center justify-center">
                  <img
                    v-if="item.avatar && item.avatar?.length !== 0"
                    :src="item?.avatar"
                  />
                  <span v-else> {{ item.reviewName?.slice(-2) }}</span>
                </div>
                <p class="jointHearing-name margin-left">
                  {{ item.reviewName }}
                </p>
              </div>
              <div class="fraction">{{ item.superiorReviewScore }}</div>
            </div>
            <el-form-item label="优势 :">
              <div>{{ item.superiorReviewAdvantage }}</div>
            </el-form-item>
            <el-form-item label="劣势 :">
              <div>{{ item.superiorReviewImprovement }}</div>
            </el-form-item>
          </div>
          <div
            v-if="planList.openReview === 1"
            class="
              bg-white
              border-round-12
              jointHearing
              padding-tb
              box-ing
              margin-top
            "
          >
            <div class="flex justify-between align-center">
              <p>上级复评</p>
              <div class="flex align-center justify-center" v-if="sum > 0">
                <span style="font-size: 16px; color: rgba(0, 0, 0, 0.45)"
                  >会审得分均值:
                </span>
                <span class="fraction">{{ sum }}</span>
              </div>
            </div>
          </div>
          <template v-if="planList.openReview === 1">
            <el-form-item
              prop="superiorReviewScore"
              :rules="[
                {
                  required: planState !== 7,
                  message: '得分必填',
                  trigger: 'blur',
                },
              ]"
              label="复核得分"
            >
              <el-input-number
                placeholder="请输入复核得分"
                v-model="planList.superiorReviewScore"
                :min="0"
                :precision="2"
              />
            </el-form-item>
            <el-form-item
              prop="superiorReviewAdvantage"
              label="优势 :"
              :rules="[
                {
                  required: planState !== 7 && userid !== '0333266228557604405',
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="请输入优势"
                v-model="planList.superiorReviewAdvantage"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              prop="superiorReviewImprovement"
              label="劣势 :"
              :rules="[
                {
                  required: planState !== 7 && userid !== '0333266228557604405',
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="请输入劣势"
                v-model="planList.superiorReviewImprovement"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </template>
        </div>
        <div
          class="text-center margin-bottom-30"
          v-if="isSuperior && planState === 6"
        >
          <!--  会审提交-->
          <el-button
            :isLoading="isLoading"
            v-if="
              planList.jointAssessEntities.length === 0 && isJoinAssessor === 1
            "
            @click="onJointSave"
            >提交
          </el-button>
          <!--  复审通过-->
          <el-button
            :isLoading="isLoading"
            v-if="planList.openReview === 1 && Number(showHidden) === 0"
            @click="onUpdateMonthPlan"
            >提交
          </el-button>
        </div>
      </el-form>
      <Flow
        v-if="planState === 5 && !isSuperior"
        :submit="submit"
        :isLoading="isLoading"
      />
    </div>
    <Flow
      class="margin-left"
      :isJoinAssessor="isJoinAssessor"
      :propsPlanState="planState"
      :type="types"
      v-if="planState !== 5 || isSuperior"
      style="width: 472px; height: calc(100vh - 122px)"
    />
  </div>
</template>
<script>
// @ is an alias to /src
import { nextTick, ref, watchEffect } from 'vue'
import Flow from '@/components/Flow'
import { useRoute } from 'vue-router'
import {
  addSelfAssess,
  jointSave,
  seeDetail,
  selectMonthPlan,
  updateMonthPlan,
  updatePlanState,
  submitAgain,
  getOssCode,
  withdraw
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
import OSS from 'ali-oss'
import moment from 'moment'
export default {
  name: 'PerformancePlan',
  components: {
    Flow
  },
  setup () {
    const route = useRoute()
    const planRef = ref(null)
    const isSuperior = ref(false)
    const planState = ref(1)
    const planUser = ref(' ')
    const isLoading = ref(false)
    const store = useStore()
    const isJoinAssessor = ref(3)
    const sum = ref(0)
    const noSuperiorOperation = ref(1)
    const resubmit = ref(0)
    const client = ref(null)
    const client2 = ref(null)
    const fileList = ref([])
    const fileData = ref([])
    const userid = ref(JSON.parse(sessionStorage.getItem('user')).userid)
    const showHidden = ref(0)
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const showDoc = ref(false)
    const fileUrl = ref('')
    const fileWidth = ref('min-width = 300px')
    const showPdf = ref(false)
    const pdfUrl = ref('')
    const types = ref('')
    const month = ref('')
    const preview = ref('')
    const planList = ref({
      info: [],
      reviewEntityList: [],
      jointAssessEntities: [],
      selfAssessAdvantage: '',
      selfAssessImprovement: '',
      // 上级劣势
      superiorReviewImprovement: '',
      // 上级优势
      superiorReviewAdvantage: '',
      // 上级打分
      superiorReviewScore: undefined,
      publishName: '',
      departmentName: '',
      position: '',
      selfAssessScore: 0,
      jointReviewScore: undefined,
      jointReviewAdvantage: '',
      openReview: 1
    })
    const zongFen = (e) => {
      let all = 0
      planList.value.info.forEach((item) => {
        if (!isNaN(item.evaluationScores)) {
          all += +item.evaluationScores
        }
      })
      console.log(all)
      planList.value.selfAssessScore = all.toFixed(2)
    }
    // 自评提交
    const submit = () => {
      planRef.value.validate((valid) => {
        console.log(valid, 22)
        if (valid) {
          const monthPlanItemEntityList = []
          let selfAssessScore = 0
          const { info, selfAssessAdvantage, selfAssessImprovement } =
            planList.value
          info.forEach((item) => {
            selfAssessScore += Number(item.evaluationScores)
            monthPlanItemEntityList.push({
              id: item.id,
              evaluationScores: item.evaluationScores,
              completion: item.completion,
              pictures: JSON.stringify(item.pictures) || '',
              attachment: JSON.stringify(item.attachment) || ''
            })
          })
          selfAssessScore.toFixed(2)
          if (selfAssessScore > 150) {
            return ElMessage.error('得分最大值为150')
          }
          const monthPlanEntity = {
            id: Number(route.query.id),
            selfAssessScore,
            planState: 6,
            month: month.value,
            selfAssessAdvantage,
            selfAssessImprovement
          }
          isLoading.value = true
          addSelfAssess({
            monthPlanEntity,
            monthPlanItemEntityList
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              ElMessage.success('提交成功')
              store.action.updateIsSubmit(true)
            }
          })
        } else {
          isLoading.value = false
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }
    // 页面数据回显
    const getPlanList = (data) => {
      console.log(data)
      data.monthPlanItemEntityList.forEach((item) => {
        if (item.attachment) {
          item.attachment = JSON.parse(item.attachment) || []
        }
        if (item.pictures) {
          item.pictures = JSON.parse(item.pictures) || []
        }
      })
      types.value = data.type
      planList.value = data.monthPlanEntity
      planState.value = data.monthPlanEntity.planState
      planUser.value = data.monthPlanEntity.publishId
      planList.value.info = data.monthPlanItemEntityList
      planList.value.jointAssessEntities = data.jointAssessEntities || []
      planList.value.reviewEntityList = data.reviewEntityList || []
      planList.value.openReview = data.openReview
      noSuperiorOperation.value = data.noSuperiorOperation
      resubmit.value = data.resubmit
      if (data.jointAssessEntities && data.jointAssessEntities.length > 0) {
        let average = 0
        data.jointAssessEntities.forEach((item) => {
          average += Number(item.jointReviewScore)
        })
        sum.value = Math.round(average / data.jointAssessEntities.length)
      }
    }
    // 获取自评页面数据
    const getInfo = () => {
      if (!route.query.id) {
        return
      }
      selectMonthPlan({ id: route.query.id }).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          month.value = res.data?.monthPlanEntity.month
          getPlanList(res.data)
        }
      })
    }
    // 上级查看员工页面数据
    const getDetail = () => {
      noSuperiorOperation.value = 1
      resubmit.value = 0
      const { userid } = store.state.user
      seeDetail({
        userid: userid || route.query.userid,
        planId: route.query.id
      }).then((res) => {
        console.log(res.data)
        if (res.code === 200) {
          isJoinAssessor.value = res.data.isJoinAssessor
          store.action.updateIsJoinAssessor(res.data.isJoinAssessor)
          getPlanList(res.data)
        }
      })
    }
    // 撤销
    const onNoSuperiorOperation = () => {
      isLoading.value = true
      withdraw({
        planId: Number(route.query.id)
      }).then((res) => {
        isLoading.value = false
        if (res.code === 200) {
          ElMessage.success(res.msg)
          store.action.updateIsSubmit(true)
        }
      })
    }
    // 再次提交
    const onResubmit = () => {
      isLoading.value = true
      submitAgain({
        planId: Number(route.query.id)
      }).then((res) => {
        isLoading.value = false
        if (res.code === 200) {
          store.action.updateIsSubmit(true)
        }
      })
    }
    // 上级提交
    const onUpdateMonthPlan = () => {
      planRef.value.validate((valid) => {
        if (valid) {
          const {
            superiorReviewImprovement,
            superiorReviewAdvantage,
            superiorReviewScore
          } = planList.value
          if (superiorReviewScore > 150) {
            return ElMessage.error('复合得分最大值为150')
          }
          isLoading.value = true
          updateMonthPlan({
            reviewUserid: route.query.userid || store.state.user.userid,
            planId: route.query.id,
            // 上级劣势
            superiorReviewImprovement,
            // 上级优势
            superiorReviewAdvantage,
            // 上级打分
            superiorReviewScore
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              isLoading.value = true
              updatePlanState({
                planId: route.query.id,
                checkResult: 1,
                approvePersonId: route.query.userid || store.state.user.userid
              }).then((res) => {
                isLoading.value = false
                if (res.code === 200) {
                  showHidden.value = 1
                  ElMessage.success('提交成功')
                  getDetail()
                  store.action.updateIsDetail(true)
                }
              })
            }
          })
        } else {
          isLoading.value = false
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }
    // 会审提交
    const onJointSave = () => {
      planRef.value.validate((valid) => {
        if (valid) {
          const { userid, name } = store.state.user
          const { jointReviewAdvantage, jointReviewScore } = planList.value
          if (jointReviewScore > 150) {
            return ElMessage.error('复合得分最大值为150')
          }
          isLoading.value = true
          jointSave({
            monthPlanId: route.query.id,
            jointPerson: name,
            jointPersonId: userid || route.query.userid,
            jointReviewAdvantage,
            jointReviewScore
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              ElMessage.success('提交成功')
              getDetail()
              store.action.updateIsDetail(true)
            }
          })
        } else {
          isLoading.value = false
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }
    const upload = (file, index) => {
      console.log(file)
      getOssCode({}).then((res) => {
        if (res.StatusCode === 200) {
          const clients = new OSS({
            region: 'oss-cn-hangzhou',
            accessKeyId: res.AccessKeyId,
            accessKeySecret: res.AccessKeySecret,
            stsToken: res.SecurityToken,
            bucket: 'pr-mdf-erp'
          })
          client.value = clients
          const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png'
          if (!isJpgOrPng) {
            return ElMessage.error('上传的文件格式只能是jpg,png')
          }
          const isLt2M = file.size / 1024 / 1024 < 20
          if (!isLt2M) {
            return ElMessage.error('文件不能大于20M，上传失败')
          }
          putObject(file, index)
          return false
        }
      })
    }
    const upload2 = (file1, index) => {
      getOssCode({}).then((res) => {
        if (res.StatusCode === 200) {
          const clients2 = new OSS({
            region: 'oss-cn-hangzhou',
            accessKeyId: res.AccessKeyId,
            accessKeySecret: res.AccessKeySecret,
            stsToken: res.SecurityToken,
            bucket: 'pr-mdf-erp'
          })
          client2.value = clients2
          putObject2(file1, index)
          return false
        }
      })
    }
    const exceedImg = () => {
      ElMessage.error('上传图片最多9张')
    }
    const putObject = async (data, index) => {
      try {
        const name = data.type.substring(data.type.lastIndexOf('/') + 1)
        const result = await client.value.put(
          `/storage/${moment().format('YYYY-MM')}/${data.name}`,
          data
        )
        console.log(result)
        ElMessage.success('上传成功')
        if (!planList.value.info[index].pictures) {
          planList.value.info[index].pictures = []
        }
        planList.value.info[index].pictures.push({
          url: result.url,
          name: result.name
        })
      } catch (e) {
        ElMessage.error('上传失败')
      }
    }
    const putObject2 = async (data, index) => {
      console.log(data)
      try {
        const name = data.name.substring(data.name.lastIndexOf('.') + 1)
        const result = await client2.value.put(
          `/storage/${moment().format('YYYY-MM')}/${data.name}`,
          data
        )
        ElMessage.success('上传成功')
        if (!planList.value.info[index].attachment) {
          planList.value.info[index].attachment = []
        }
        planList.value.info[index].attachment.push({
          url: result.url,
          name: result.name
        })
      } catch (e) {
        ElMessage.error('上传失败')
      }
    }
    const handleRemove = (file) => {
      console.log(file)
      if (planUser.value !== userid.value || Number(planState.value) !== 5) {
        ElMessage.error('您当前没有此操作权限')
        return
      }
      planList.value.info.forEach((item) => {
        console.log(item)
        if (item.pictures && item.pictures.length > 0) {
          item.pictures.forEach((pic, index) => {
            console.log(pic)
            if (pic.url === file.url) {
              item.pictures.splice(index, 1)
              ElMessage.success('删除图片成功')
            }
          })
        }
      })
    }

    const handlePreview = (file) => {}
    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }
    const handleDownload = (file) => {
      console.log(file)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = file.url
      link.download = link.href
      link.click()
    }
    const handleRemove2 = (file1, fileData) => {
      console.log(file1)
      if (planUser.value !== userid.value || Number(planState.value) !== 5) {
        ElMessage.error('您当前没有此操作权限')
        return
      }
      planList.value.info.forEach((item) => {
        console.log(item)
        if (item.attachment && item.attachment.length > 0) {
          item.attachment.forEach((pic, index) => {
            console.log(pic)
            if (pic.url === file1.url) {
              item.attachment.splice(index, 1)
              ElMessage.success('删除附件成功！')
            }
          })
        }
      })
    }
    const handlePreview2 = (file1) => {
      preview.value = file1
      console.log(file1)
    }
    const onExceed = () => {
      ElMessage.error('请勿超过上传上限')
    }
    const download = (index) => {
      if (!preview.value.url) {
        return ElMessage.error('请先点击或上传文件')
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = preview.value.url
      link.download = link.href
      link.click()
      preview.value = ''
    }
    const showFile = () => {
      if (!preview.value.url) {
        return ElMessage.error('请先点击或上传文件')
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href =
        'https://view.officeapps.live.com/op/view.aspx?src=' +
        preview.value.url
      link.target = '_blank'
      link.click()
      preview.value = ''
    }
    watchEffect(() => {
      if (route.query) {
        planState.value = Number(route.query.planState)
        if (route.query.isSuperior) {
          getDetail()
          isSuperior.value = route.query.isSuperior
        } else {
          getInfo()
        }
      }
    })
    return {
      planList,
      submit,
      planRef,
      isSuperior,
      planState,
      userid,
      planUser,
      isJoinAssessor,
      onJointSave,
      sum,
      onUpdateMonthPlan,
      isLoading,
      noSuperiorOperation,
      resubmit,
      onNoSuperiorOperation,
      onResubmit,
      upload,
      client,
      client2,
      handleRemove,
      handlePreview,
      fileList,
      upload2,
      putObject2,
      fileData,
      handleRemove2,
      handlePreview2,
      download,
      dialogImageUrl,
      dialogVisible,
      handlePictureCardPreview,
      handleDownload,
      onExceed,
      showFile,
      showDoc,
      fileWidth,
      showPdf,
      pdfUrl,
      fileUrl,
      zongFen,
      exceedImg,
      preview,
      showHidden,
      types
    }
  }
}
</script>
<style scoped lang="less">
.PerformanceDetails-name {
  font-size: 20px;
  font-weight: 600;
}

.PerformanceDetails-position {
  font-size: 16px;
}
.el-upload-dragger {
  width: 80px !important;
  height: 25px !important;
  display: inline-block !important;
}
.PerformanceDetails {
  overflow: hidden;

  .fraction {
    color: #fb4343;
    font-size: 24px;
    font-family: Arial, Arial-BoldItalicMT;
    font-weight: 600;
  }
  //.wcxq{
  //  margin: 30px 0;
  //}
  .el-form-item {
    margin: 30px 0;
  }
  .content-box {
    width: 960px;
    height: calc(100vh - 122px);
    overflow: auto;
    .el-button--small::v-deep {
      padding: 8px 16px;
      color: #1890ff;
      border-color: #1890ff;
      border-radius: 8px;
      font-size: 16px;
    }
  }
  .sctup {
    background: #fff !important;
    width: 80px;
    height: 25px;
    margin: 10px 20px;
    border: 0 !important;
    text-align: center;
  }
  .zdxz {
    color: #c1c1c1;
    font-size: 14px;
    margin-left: 20px;
  }

  &-form-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &-form::v-deep {
    .el-input__inner {
      padding: 0 8px;
    }
    .el-form-item__label {
      float: none;
    }

    label {
      width: auto !important;
    }

    .el-form-item {
      margin-bottom: 8px;
    }

    .el-form-item__content {
      margin: 0 !important;
    }
    .el-input-number {
      .el-input__inner {
        padding: 0 15px;
        text-align: left;
      }
    }
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none !important;
    }
  }
}

.jointHearing {
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  &-name {
    font-size: 20px;
    margin-right: 40px;
  }

  &-position {
    font-size: 20px;
  }

  &-img {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    background: #3296fa;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }

    span {
      font-size: 12px;
      color: #fff;
    }
  }
}
</style>
